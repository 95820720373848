@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@font-face {
  font-family: "Helvetica Neue";
  src: url("/src/assets/fonts/HelveticaNeueRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("/src/assets/fonts/HelveticaNeueMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("/src/assets/fonts/HelveticaNeueBold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("/src/assets/fonts/HelveticaNeueBlack.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* Animations */
@keyframes customLoader {
  0% {
    transform: rotate(0deg);
  }
  33.33% {
    transform: rotate(360deg);
  }
  66.66% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.loader {
  animation: customLoader 2.5s infinite;
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s;
}

/* Snap Scroll Container */
div[ref] {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

/* section {
  scroll-snap-align: start;
} */

.qr-reader {
  width: 430px;
  /* height: 100vh; */
  height: 430px;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#party-view {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}
